import request from '../until/request.js'

// 发表问答交流
export const addQue = data => {
  return request.post('/admin/addQue', data)
}
//  编辑交流问答
export const editQue = data => {
  return request.post('/admin/editQue?id=', data)
}
// 获取用户发表的问答交流
export const getUserPubQuestion = data => {
  return request.get('/admin/getUserPubQuestion')
}

// 获取用户发表的问答交流详情
export const getDetailQue = data => {
  return request.get('/admin/getDetailQuestion?id=' + data)
}

// 获取问答交流的评论
export const getComments = data => {
  return request.get('/admin/getComments')
}

// 真删除发表的问答交流
export const rDelQue = data => {
  return request.post('/admin/rDelQue', data)
}

// 假删除发表的问答交流
export const delQue = data => {
  return request.post('/admin/delQue', data)
}

// 下架问答交流
export const downQue = data => {
  return request.post('/admin/downQue', data)
}

// 恢复下架的问答交流
export const backDownQue = data => {
  return request.post('/admin/backDownQue', data)
}

// 删除下架的问答交流
export const delDownQue = data => {
  return request.post('/admin/delDownQue', data)
}
// 恢复删除的问答交流
export const backDelQue = data => {
  return request.post('/admin/backDelQue', data)
}

// 访客获取问答列表
export const getQue = data => {
  return request.get('/api/getQue')
}
// 访客获取问答列表详情
export const getDetailQues = data => {
  return request.get('/api/getDetailQue?id=' + data)
}
export const getQueLikes = data => {
  return request.get('/api/getQueLikes?id=' + data)
}
// 交流问答的点赞
export const likeQue = data => {
  return request.post('/admin/likeQue',data)
}
// 交流问答取消点赞
export const unLikeQue = data => {
  return request.post('/admin/unLikeQue',data)
}

// 交流问答获取我的点赞
export const getMyLike = data => {
  return request.get(`/admin/getMyLike?question_id=`+data);
};
// // 更新我的点赞
// export const updateMyLike = data => {
//   return request.post(`/admin/updateMyLike?question_id=`+data);
// };






