<template>
  <div class="question" >
    <div style="min-height:92.9vh">
    <Head></Head>
    <div v-show="isShow" class="mainq"  >
            <div class="queInfo"

             v-for="(item,index) in questions" :key="index"
              @click="$router.push('/question/'+item.id,{params:{queId:item.id}})"
             >
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="tag">
              <p>{{ item.tag }}</p>
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <!-- <div class="depart"> 
          <p>{{item.department}}</p>
        </div> -->

        <!-- <div class="content">
            <p >{{item.content}}</p>
        </div> -->
        <!-- <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div> -->
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>
                     <li><i class="like-icon"></i><span>{{item.likes}}</span></li>
                     <li><i class="comment-icon"></i><span>{{item.commentNum}}</span></li>
                </ul>
            </div>
            <div class="edit">
                <ul>
                    <li @click="$router.push('/question/'+item.id,{params:{queId:item.id}})">查看详情</li>

                </ul>

            </div>
        </div>
        </div>
    </div>

    <el-empty class="el-empty" v-show="!isShow" :image-size="200"></el-empty>

        </div>
            <router-view @showSchInfo="showSch"></router-view>
        <div>
          
   <Foot ></Foot>
    </div>
  </div>
</template>

<script>
import head from '../head.vue'
import { getQue } from '../../API/Question.js'
import Foot from '../../views/foot.vue'
export default {
  name: 'question',
  data () {
    return {
      questions: [],
      isShow: false
    }
  },
  methods: {
    // 获取所有文章
    showSch (e) {
      console.log(e)
    },
    async getSchArts () {
      const { data: res } = await getQue()
      // console.log(res)
      if (res.status === 0) {
        // console.log(res.data)
        res.data.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          if (item.isdelete === 0 && item.isdown === 0) {
            this.questions = [...this.questions, item]
          }
        })
      }

      if (this.questions.length !== 0) {
        this.isShow = true
        console.log(this.questions)
      } else {
        this.isShow = false
      }
    }
  },
  components: {
    Head: head,
    Foot: Foot
  },
  created () {
    this.getSchArts()
  }
}
</script>

<style lang="less" scped>
*{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}
.mainq{
  width: 80%;
  margin: 30px auto;

.queInfo{
    overflow-x: hidden;
    background-color:#fff ;
    border-bottom: 1px solid #dbdbdf;
    cursor: pointer;
    .top{
        display: flex;
        justify-content: space-between;
        padding: 20px 25px;
        .title{
            display: flex;
            align-items: center;
            p{
                margin: 0 20px;
                color: #FF3535;
                font-size: 24px;
            }
            h3{
                span{
                    color: #d47171;
                }
            }
        }
        .data{
            min-width: 142px;
            text-align: right;
            height: 24px;
            line-height: 24px;
        }
    }
    .content{
         padding: 5px 25px;
        //  overflow: hidden;
         height: 60px;
         p{
          // width: 430px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 30px;
          -webkit-box-orient: vertical;
          text-indent: 12px;
         }
    }
    .depart {
        font-size: 14px;
        padding: 10px 20px 0 0;
        color: #585858;
      }
    .tag {
        display: inline-block;
        padding: 4px 10px; /* 内边距，可以根据需要调整 */
        background-color: #4CAF50; /* 绿色背景 */
        color: red; /* 文字颜色 */
        border-radius: 16px; /* 圆角，让边框看起来像长圆框 */
        border: 2px solid #4CAF50; /* 边框颜色和宽度，这里设置为与背景色相同，以形成完整的效果 */
        font-size: 14px; /* 字体大小 */
        line-height: 1; /* 保持文本垂直居中 */
        // margin-left: 70px;
    }
    .info{
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
        .views{
            ul{
                display: flex;
                 li{
                    margin: 0 5px;
                     i{
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        vertical-align: bottom;
                        margin-right: 8px;
                        background-size: 16px!important;
                    }
                    .view-icon{
                        background: url('../../../public/images/浏览.png') no-repeat;
                    }
                    .like-icon{
                        background: url('../../../public/images/点赞.png');
                    }
                    .comment-icon{
                        background: url('../../../public/images/消息.png');
                    }
                }
            }
        }
        .edit{
            ul{
                display: flex;
                li{
                    margin: 0 5px;
                    cursor: pointer;
                }
               li:hover{

                   color: #ff3535;
               }
            }
        }
    }
  }
  .queInfo:hover{
    background-color: #f3f3f3;
  }
}
.el-empty{
  height: 85vh;
}

</style>
